$(document).on('keydown', '#corporate_profiles_update_form_fixed_ffs_unit_price_level,' +  
               '#corporate_profiles_update_form_fixed_ffs_new_unit_price,' + 
               '#corporate_profiles_update_form_fixed_ffs_update_unit_price', function (e) { 
  
  var inputBox = document.getElementById($(this).attr('id'));
                
  var invalidChars = [
    "-",
    "+",
    "e",
  ];


  inputBox.addEventListener("input", function () {
    this.value = this.value.replace(/[e\+\-]/gi, parseInt(this.value));
  });

  inputBox.addEventListener("keydown", function (e) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  });
})

$(function () {
  if ($("#flexSwitchEthosFlagShow").val() == 'true') {
    $("#flexSwitchEthosFlagShow").prop("checked", true)
  }
  if ($("#flexSwitchCheckDefault").val() == 'true') {
    $("#flexSwitchCheckDefault").prop("checked", true)
  }
  $("#flexSwitchCheckDefault").change(function () {
    if ($(this).prop("checked") == true) {
      $('#flexSwitchCheckDefault').prop('checked', true);
      $(this).val(true);
    } else {
      $("#flexSwitchCheckDefault").prop("checked", false)
    }
  });
})
