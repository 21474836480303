// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('./static/images', true, /\.(png|jpg|jpeg|svg|ico)$/);

import Rails from '@rails/ujs';
// require("@rails/ujs").start();
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
window.Rails = Rails;
import 'channels';
// css
import './styles/application.scss'
// javascript
import './scripts/application';
import './scripts/custom';
import "../controllers";
Rails.start();
// Turbolinks.start()
// ActiveStorage.start()
