import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "inputFileCsvEmployees",
    "inputFileCsvDivisions",
    "formImport",
    "nameFileDivisions",
    "nameFileEmployees",
    "btnSubmit"
  ];

  static values = {
    urlImportValidatesOldSystems: String,
    urlImportOldSystems: String
  };

  appendFileName(e) {
    let fileName = e.currentTarget.value.split(/(\\|\/)/g).pop();
    const btnSubmit = this.btnSubmitTarget;
    if (
      e.currentTarget.dataset["importOldSystemsTarget"] ==
      "inputFileCsvDivisions"
    ) {
      this.nameFileDivisionsTarget.textContent = fileName;
    } else {
      this.nameFileEmployeesTarget.textContent = fileName;
    }
    btnSubmit.classList.remove("bottom-0");
    btnSubmit.classList.add("bottom-5", "translate-middle-y");
  }

  importFile(e) {
    e.preventDefault();
    if (e.currentTarget.dataset["csvType"] == "divisions") {
      this.inputFileCsvDivisionsTarget.click();
    } else {
      this.inputFileCsvEmployeesTarget.click();
    }
  }

  submitForm(e) {
    e.preventDefault();
    if (
      this.inputFileCsvDivisionsTarget.files.length == 0 ||
      this.inputFileCsvEmployeesTarget.files.length == 0
    ) {
      alert("ファイルを追加してください");
      return;
    }

    const formData = new FormData(this.formImportTarget);
    $("#loader-ffs").removeClass("hidden");
    $.ajax({
      url: this.urlImportValidatesOldSystemsValue,
      type: "POST",
      processData: false,
      contentType: false,
      data: formData,
      success: (data) => {
        $(".import-data-info-wrapper").html(data);
        $("#import-data-info").addClass("show");
        $("#import-data-info").show();
      },
      complete: () => {
        $("#loader-ffs").addClass("hidden");
      },
      error: () => {
        alert("エラーが発生しました。");
        location.reload();
      }
    });
  }

  submitData() {
    $("#import-data-info").removeClass("show");
    $("#import-data-info").hide();
    this.formImportTarget.submit();
  }

  cancelSubmit() {
    location.reload();
  }
}
