import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["formSearch", "availableDateToStart", "availableDateToEnd"];

  submitSearch(e) {
    e.preventDefault();
    let startDate = this.availableDateToStartTarget.value;
    let endDate = this.availableDateToEndTarget.value;
    if (
      !isNaN(Date.parse(endDate)) &&
      !isNaN(Date.parse(startDate)) &&
      Date.parse(endDate) < Date.parse(startDate)
    ) {
      alert(
        "更新期限(to)は、更新期限(from)以降の日付となるよう入力してください。"
      );
    } else {
      this.formSearchTarget.submit();
    }
  }
}
