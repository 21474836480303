import "adminator/src/assets/scripts/index";
import "daterangepicker";
import { DEFAULT_DATE_FORMAT, APP_LOADER_TIMING } from "./const";

const App = (() => {
  const start = () => {
    appLoader();
    handleLogout();
    setupDatePickers();
  };

  const appLoader = () => {
    window.addEventListener("load", function load() {
      const loader = document.getElementById("loader");
      setTimeout(function () {
        loader.classList.add("fadeOut");
      }, APP_LOADER_TIMING);
    });
  };

  const handleLogout = () => {
    $("#logout").on("click", () => {
      const loader = document.getElementById("loader");
      loader.classList.remove("fadeOut");
    });
  };

  const setupDatePickers = () => {
    $(".datepicker").datepicker({
      format: DEFAULT_DATE_FORMAT.toLocaleLowerCase(),
    });
    setupDateRangePicker();
  };

  const setupDateRangePicker = (className = ".input-daterange") => {
    $(className).daterangepicker({
      autoUpdateInput: false,
      locale: {
        format: DEFAULT_DATE_FORMAT,
        cancelLabel: "Clear",
      },
    });

    $(className).on("apply.daterangepicker", function (_ev, picker) {
      const startDate = picker.startDate.format(DEFAULT_DATE_FORMAT);
      const endDate = picker.endDate.format(DEFAULT_DATE_FORMAT);
      $(this).val(`${startDate} 〜 ${endDate}`);
    });

    $(className).on("cancel.daterangepicker", function (_ev, _picker) {
      $(this).val("");
    });
  };

  return {
    start: start,
  };
})();

App.start();
